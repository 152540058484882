// src/components/Buttons/Button.tsx
import React from 'react';
import Loader from '../../components/Loader/Loader'; // Adjust the import path as necessary

interface ButtonProps {
    onClick?: () => void;
    disabled?: boolean;
    children: React.ReactNode;
    className?: string;
    loading?: boolean; // New loading prop
}

const Button: React.FC<ButtonProps> = ({ onClick, disabled, children, className, loading }) => {
    return (
        <button onClick={onClick} disabled={disabled || loading} className={`btn ${className}`}>
            {loading ? <Loader loading={true} size={8} /> : children} {/* Show loader when loading is true */}
        </button>
    );
};

export default Button;
