// src/components/Cart/Cart.tsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Cart.css';
import { useCart } from '../../Context/CartContext';

const Cart: React.FC = () => {
    const navigate = useNavigate();
    const { cartItems, refreshCart } = useCart(); 

    const calculateTotal = () => {
        return cartItems.reduce((total, item) => {
            const variantPrice = item.variantDetails ? item.variantDetails.price : item.item_price;
            const toppingsTotal = item.selectedToppings.reduce((sum, topping) => sum + topping.price, 0);
            return total + (variantPrice + toppingsTotal) * item.cartQuantity;
        }, 0).toFixed(2);
    };

    const handleQuantityChange = async (id: number, delta: number) => {
        const updatedItems = cartItems.map(item =>
            item.item_id === id ? { ...item, cartQuantity: Math.max(1, item.cartQuantity + delta) } : item
        );
        await refreshCart(); // Refresh context data after updating
    };

    const handleReviewClick = () => {
        // Navigate to OrderList and pass the cart data
        navigate('/checkout', { state: { cartItems } });
    };

    return (
        <div className="cart">
            <div className="delivery-options">
                <button className="active">Delivery <span>(25 - 40 mins)</span></button>
                <button>Pick-up <span>Not available</span></button>
            </div>
            <h3>Your items</h3>
            <div className="cart-items">
                {cartItems.map(item => (
                    <div key={item.item_id} className="cart-item">
                        <img src={item.item_photo} alt={item.item_name} className="item-image" />
                        <div className="item-details">
                            <div className="item-name">{item.item_name}</div>
                            {item.variantDetails && (
                                <div className="item-variant">{item.variantDetails.variantName}</div>
                            )}
                            <div className="item-description">
                                {item.selectedToppings.map(topping => (
                                    <span key={topping.toppingId} className="item-topping">
                                        {topping.toppingName} (Rs. {topping.price.toFixed(2)})
                                    </span>
                                ))}
                            </div>
                            <div className="item-price">
                                Rs. {(item.variantDetails?.price || item.item_price).toFixed(2)}
                            </div>
                        </div>
                        <div className="item-actions">
                            <button onClick={() => handleQuantityChange(item.item_id, -1)}>&#128465;</button>
                            <span>{item.cartQuantity}</span>
                            <button onClick={() => handleQuantityChange(item.item_id, 1)}>+</button>
                        </div>
                    </div>
                ))}
            </div>
            <div className="cart-summary">
                <div className="total">Total (incl. fees and tax)</div>
                <div className="total-amount">Rs. {calculateTotal()}</div>
                <button className="review-button" onClick={handleReviewClick}>Review payment and address</button>
            </div>
        </div>
    );
};

export default Cart;
