// src/utils/geolocationUtils.ts
export const fetchAddress = async (lng: number, lat: number): Promise<string> => {
    const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
    
    if (!accessToken) {
        console.error("Mapbox access token is missing");
        return "Access token not provided";
    }

    try {
        const response = await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${accessToken}`
        );
        const data = await response.json();
        if (data && data.features && data.features.length > 0) {
            return data.features[0].place_name;
        } else {
            return "Address not found";
        }
    } catch (error) {
        console.error("Error fetching address:", error);
        return "Error fetching address";
    }
};
