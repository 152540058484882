import React from 'react';
import './footer.css';
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin, FaYoutube, FaApple, FaGooglePlay } from 'react-icons/fa';

const Footer = () => {
    const year = new Date().getFullYear();

    return (
        <footer className="footer bg-light pt-4">
            <div className="container">

            <div className="row">
    <div className="col-12">
        {/* Logo and Country/Language Selection Section */}
        <div className="footer-top d-flex flex-column flex-sm-row align-items-center justify-content-between mb-4">
            <img
                src="/static/media/logo.be5a99b5103aa4358e39.png"
                alt="Zomato logo"
                className="footer-logo mb-3 mb-sm-0"
            />
            <div className="d-flex flex-column flex-sm-row">
                {/* Country Selection */}
                <div className="dropdown me-0 me-sm-3 mb-2 mb-sm-0">
                    <button
                        className="btn btn-light dropdown-toggle"
                        type="button"
                        id="countryDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        Select Country
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="countryDropdown">
                        <li><a className="dropdown-item" href="#">India</a></li>
                        <li><a className="dropdown-item" href="#">UAE</a></li>
                        <li><a className="dropdown-item" href="#">USA</a></li>
                        {/* Add more countries as needed */}
                    </ul>
                </div>
                {/* Language Selection */}
                <div className="dropdown">
                    <button
                        className="btn btn-light dropdown-toggle"
                        type="button"
                        id="languageDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        Select Language
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="languageDropdown">
                        <li><a className="dropdown-item" href="#">English</a></li>
                        <li><a className="dropdown-item" href="#">Español</a></li>
                        <li><a className="dropdown-item" href="#">Français</a></li>
                        {/* Add more languages as needed */}
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

                <div className="row">
                    {/* About Sumbite Section */}
                    <div className="col-md-3">
                        <h6 className="text-uppercase font-weight-bold">About Sumbite</h6>
                        <ul className="list-unstyled">
                            <li><a href="#">Who We Are</a></li>
                            <li><a href="#">Blog</a></li>
                            <li><a href="#">Work With Us</a></li>
                            <li><a href="#">Investor Relations</a></li>
                            <li><a href="#">Report Fraud</a></li>
                            <li><a href="#">Contact Us</a></li>
                        </ul>
                    </div>
                    {/* Zomaverse Section */}
                    <div className="col-md-3">
                        <h6 className="text-uppercase font-weight-bold">Zomaverse</h6>
                        <ul className="list-unstyled">
                            <li><a href="#">Sumbite</a></li>
                            <li><a href="#">Blinkit</a></li>
                            <li><a href="#">Feeding Pakistan</a></li>
                            <li><a href="#">Lahore</a></li>
                            <li><a href="#">Islamabad</a></li>
                        </ul>
                    </div>
                    {/* For Restaurants Section */}
                    <div className="col-md-3">
                        <h6 className="text-uppercase font-weight-bold">For Restaurants</h6>
                        <ul className="list-unstyled">
                            <li><a href="#">Partner With Us</a></li>
                            <li><a href="#">Apps For You</a></li>
                        </ul>
                    </div>
                    {/* Social Links Section */}
                    <div className="col-md-3">
                        <h6 className="text-uppercase font-weight-bold">Social Links</h6>
                        <div className="d-flex mb-2">
                            <a href="#" className="me-2"><FaLinkedin /></a>
                            <a href="#" className="me-2"><FaInstagram /></a>
                            <a href="#" className="me-2"><FaTwitter /></a>
                            <a href="#" className="me-2"><FaYoutube /></a>
                            <a href="#"><FaFacebook /></a>
                        </div>
                        {/* App Store and Google Play Icons */}

                        <a href="#">
                            <FaGooglePlay size={30} className="text-dark" /> Get it on Google Play
                        </a>
                    </div>
                </div>
                <hr className="my-4" />
                <div className="text-center">
                    <p>
                        By continuing past this page, you agree to our Terms of Service, Cookie Policy, Privacy Policy, and Content Policies.
                    </p>
                    <p>&copy; {year} Sumbite Ltd. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
