// src/pages/Restaurants/RestaurantDetailPage.tsx
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import RestaurantDetails from '../../components/Restaurant/Details/RestaurantDetails';
import { getItemsWithCategoryByRestaurantId, getRestaurantById } from '../../Common/api/restaurants/Restaurants';
import { CategoryWithitems } from '../../Interface/IItemsWithCategory';
import { IGetRestaurant } from '../../Interface/IGetRestaurant';
import CategoryTab from '../../components/Menu/CategoryTab';
import RestaurantMenu from '../../components/Menu/RestaurantManu';
import Cart from '../../components/Cart/Cart';
import RestaurantMapDistance from '../../components/Restaurant/DistanceMap/RestaurantMapDistance';
import Popup from '../../components/Popup/Popup';
import ItemDetailsModal from '../../components/ItemDetails/ItemDetailsModal';
import { CartProvider } from '../../Context/CartContext';

interface LocationState {
  lat: number;
  lot: number;
}

const RestaurantDetailPage: React.FC = () => {
  const location = useLocation();
  const { lat, lot } = (location.state as LocationState) || { lat: 0, lot: 0 };
  const { id } = useParams<{ id: string }>();

  const [restaurantData, setRestaurantData] = useState<IGetRestaurant | null>(null);
  const [categories, setCategories] = useState<CategoryWithitems[]>([]);
  const [activeCategory, setActiveCategory] = useState<number | null>(null);
  const sectionRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const [selectedItem, setSelectedItem] = useState(null); // Store the selected item for the modal
  const [isModalOpen, setIsModalOpen] = useState(false); // Manage modal visibility

  const openItemDetailsModal = (itemId: number) => {
    setSelectedItemId(itemId);
    setIsModalOpen(true); // Open the modal when an item is clicked
  };

  const closeItemDetailsModal = () => {
    setIsModalOpen(false); // Close the modal
    setSelectedItemId(null);
  };

  // Fetch restaurant details
  useEffect(() => {
    const fetchRestaurantData = async () => {
      try {
        setLoading(true);
        const restaurantId = Number(id);
        const data = await getRestaurantById({
          restaurant_id: restaurantId,
          currentLat: 31.5204,
          currentLon: 74.3587,
        });
        setRestaurantData(data);
      } catch (error) {
        console.error("Error fetching restaurant data:", error);
        setError("Failed to load restaurant details.");
      } finally {
        setLoading(false);
      }
    };

    fetchRestaurantData();
  }, [id]);

  // Fetch categories and items
  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        setLoading(true);
        const data = await getItemsWithCategoryByRestaurantId(Number(id));
        setCategories(data);
      } catch (error) {
        console.error('Error fetching menu data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMenuData();
  }, [id]);

  const handleCategoryClick = (categoryId: number) => {
    setActiveCategory(categoryId);
    sectionRefs.current[categoryId]?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <CartProvider>
      {error && <p>{error}</p>}
      {restaurantData ? (
        <>


          <RestaurantDetails data={restaurantData} />


          <RestaurantMapDistance
            userCoordinates={[lat, lot]}
            restaurantCoordinates={[restaurantData.lat, restaurantData.lot]}
          />

          <CategoryTab
            categories={categories}
            activeCategory={activeCategory}
            onCategoryClick={handleCategoryClick}
            setActiveCategory={setActiveCategory}
            sectionRefs={sectionRefs}
          />
          <div className="container">
            <div className="menu row">
              <div className="col-md-8">
                <RestaurantMenu
                  categories={categories}
                  sectionRefs={sectionRefs}
                  handleItemClick={openItemDetailsModal}
                />
              </div>
              <div className="col-md-4 cart-column">
                <Cart />
              </div>
            </div>
          </div>
        </>
      ) : (
        loading && <p>Loading...</p>
      )}


    {/* Render the modal if it's open */}
    {isModalOpen && selectedItemId && (
        <ItemDetailsModal
         itemId={selectedItemId} // Pass the selected item to the modal
          onClose={closeItemDetailsModal} // Close the modal when needed
        />
      )}
       </CartProvider>  
    </>
  );
};

export default RestaurantDetailPage;
