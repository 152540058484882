// src/components/Collections.tsx
import React, { useEffect, useState } from 'react';
import ShimmerCard from '../../components/Shimmer/ShimmerCard';
import './Collections.css';
import { IGetRestaurant } from '../../Interface/IGetRestaurant';
import { getNewRestaurants } from '../../Common/api/restaurants/Restaurants';
import { GetNewRestaurantsParams } from '../../Interface/IGetNewRestaurantsParams';
import { Link } from 'react-router-dom';

const Collections: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [restaurants, setRestaurants] = useState<IGetRestaurant[]>([]);
    // State to track image loading for each restaurant
    const [imageLoading, setImageLoading] = useState<{ [key: number]: boolean }>({});

    const handleImageLoad = (restaurantId: number) => {
        setImageLoading((prev) => ({ ...prev, [restaurantId]: false }));
    };

    const handleImageError = (restaurantId: number, event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        setImageLoading((prev) => ({ ...prev, [restaurantId]: false }));
        // Fallback image if image is not found
        const fallbackImage = 'https://via.placeholder.com/300x200?text=Image+Not+Available';
        const imgElement = event.target as HTMLImageElement;
        if (imgElement.src !== fallbackImage) {
            imgElement.src = fallbackImage;
        }
    };

    useEffect(() => {
        // Fetch data for new restaurants
        const fetchNewRestaurants = async () => {
            setLoading(true);
            try {
                const params: GetNewRestaurantsParams = { page: 1, pageSize: 4 }; // Adjust pageSize as needed
                const response = await getNewRestaurants(params);
                setRestaurants(response.restaurants);
            } catch (error) {
                console.error('Error fetching new restaurants:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchNewRestaurants();
    }, []);

    return (
        <div className="container mt-5">
            <h2 className="text-left">Newly Registered Restaurants</h2>
            <p className="text-left">Discover recently registered restaurants near you.</p>
            <div className="row">
                {loading ? (
                    // Render shimmer cards while loading
                    Array.from({ length: 4 }).map((_, index) => <ShimmerCard key={index} />)
                ) : (
                    restaurants.map((restaurant) => (
                        <div className="col-md-3 col-sm-6 mb-4" key={restaurant.restaurant_id}>
                           
                                 <Link 
                                to={{
                                    pathname: `/restaurant/${restaurant.restaurant_id}/${restaurant.restaurant_name.replace(/\s+/g, '-').toLowerCase()}`,
                                    state: { lat: restaurant.lat, lot: restaurant.lot }
                                } as unknown as Location}>
                                    
                            <div className="collection-card shadow-sm">
                                    {/* <img
                                        src={restaurant.cover_photo || 'https://via.placeholder.com/300x200?text=No+Image'}
                                        alt={restaurant.restaurant_name}
                                        className="card-img-top"
                                        onError={(e) => {
                                            e.currentTarget.onerror = null; // Prevent infinite loop if placeholder fails
                                            e.currentTarget.src = 'https://via.placeholder.com/300x200?text=Image+Not+Available'; // Fallback placeholder
                                        }}
                                    /> */}

                               {/* Image with loading effect */}
                               <div className={`image-container ${imageLoading[restaurant.restaurant_id] ? 'loading' : ''}`}>
                                        <img
                                            src={restaurant.cover_photo || 'https://via.placeholder.com/300x200?text=No+Image'}
                                            alt={restaurant.restaurant_name}
                                            className="card-img-top"
                                            onLoad={() => handleImageLoad(restaurant.restaurant_id)}
                                            onError={(event) => handleImageError(restaurant.restaurant_id, event)} // Type the event explicitly
                                        />
                                    </div>


                                <div className="collection-info p-3">
                                    <h3 className="collection-title">{restaurant.restaurant_name}</h3>
                                    <p className="collection-places">{restaurant.deliveryAddress}</p>
                                </div>
                            </div>
                            </Link>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default Collections;
