import React, { useState } from 'react';
import InputField from '../../components/InputFileds/InputField';
import Button from '../../components/Buttons/Button';
import { login } from '../../Common/api/auth/Auth';
import AlertMessage from '../../components/Alert_Message/AlertMessage';
import './LoginPage.css';
import { useUserContext } from '../../Context/UserContext';
import { t } from 'i18next';
import { AiOutlineQuestionCircle } from 'react-icons/ai'; 

interface LoginPageProps {
    onClose: () => void;
    onRegisterClick: () => void;
    onForgotPasswordClick: () => void;
}

interface FormErrors {
    email?: string;
    password?: string;
}

const LoginPage: React.FC<LoginPageProps> = ({ onClose, onRegisterClick, onForgotPasswordClick }) => {
    const { setIsLoggedIn, setUserInfo } = useUserContext();
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [alert, setAlert] = useState<{ severity: 'success' | 'info' | 'warning' | 'error'; message: string } | null>(null);
    const [loading, setLoading] = useState(false); // Loader state

    const validateForm = () => {
        const newErrors: FormErrors = {};

        if (!email) {
            newErrors.email = "Email is required.";
        } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
            newErrors.email = "Invalid email format.";
        }

        if (!password) {
            newErrors.password = "Password is required.";
        }

        setFormErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

   // src/pages/LoginPage/LoginPage.tsx

const handleLogin = async () => {
    if (!validateForm()) return;
    setLoading(true); // Show loader
    try {
        const userData = await login({ email, password });

        // After successful login, save the user data to localStorage
        if (userData.token) {
            localStorage.setItem('authToken', userData.token);
            localStorage.setItem('tokenExpiration', userData.expiration);
            localStorage.setItem('userName', userData.userName);
            localStorage.setItem('userId', userData.userId);
        }

        setIsLoggedIn(true);
        setUserInfo({
            name: userData.firstName + " " + userData.lastName,
            email: userData.email,
            photoUrl: userData.photoUrl || null,
        });

        setAlert({ severity: 'success', message: "Login successful!" });

    } catch (error) {
        setAlert({ severity: 'error', message: "Login failed. Please try again." });
    } finally {
        setLoading(false); // Hide loader
    }
};


    const handleAlertClose = () => {
        setAlert(null);
    };

    return (
        <>
            <div className="overlay" onClick={onClose} /> 
            <div className="login-modal">
                <h2 className="modal-title">{t('login')}</h2>

                {/* Show alert message immediately */}
                {alert && (
                    <AlertMessage 
                        severity={alert.severity} 
                        message={alert.message} 
                        onClose={handleAlertClose} 
                    />
                )}

                {/* Email input field with error */}
                <div className="input-container">
                    {formErrors.email && <p className="error-text">{formErrors.email}</p>}
                    <div className={`input-field ${formErrors.email ? 'input-error' : ''}`}>
                        <InputField 
                            type="text" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            placeholder=" " 
                            label={t('email')}
                            required 
                        />
                        {formErrors.email && <AiOutlineQuestionCircle className="question-icon" />}

                    </div>
                </div>

                {/* Password input field with error */}
                <div className="input-container">
                    {formErrors.password && <p className="error-text">{formErrors.password}</p>}
                    <div className={`input-field ${formErrors.password ? 'input-error' : ''}`}>
                        <InputField 
                            type="password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            placeholder=" " 
                            label={t('email')} 
                            required 
                        />
                        {formErrors.password && <AiOutlineQuestionCircle className="question-icon" />}

                    </div>
                </div>

                <Button className="primary-btn" onClick={handleLogin} loading={loading}>
                {t('login')}
                </Button>

                <div className="register-link">
                    <p>
                    {t('no_account')}{' '}
                        <button onClick={onRegisterClick} className="link-button">{t('register_here')}</button>
                    </p>
                    <p>
                        <button onClick={onForgotPasswordClick} className="link-button">{t('forgot_password')}</button>
                    </p>
                </div>
            </div>
        </>
    );
};

export default LoginPage;
