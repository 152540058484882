import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { FaTimes } from 'react-icons/fa';
import './LocationFinder.css';
import { fetchAddress } from '../../Common/utils/mapbox/geolocationUtils';
import { useNavigate } from 'react-router-dom';

interface LocationFinderProps {
    onClose: () => void;
    coordinates?: [number, number];
    initialLocationAddress?: string;
}

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || '';

const LocationFinder: React.FC<LocationFinderProps> = ({ onClose, coordinates, initialLocationAddress }) => {
    const mapContainer = useRef<HTMLDivElement | null>(null);
    const [map, setMap] = useState<mapboxgl.Map | null>(null);
    const [buttonEnabled, setButtonEnabled] = useState(false);
    const [locationAddress, setLocationAddress] = useState(initialLocationAddress || '');
    const [loading, setLoading] = useState(false);
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [userLocation, setUserLocation] = useState<[number, number] | null>(coordinates || null);

    const navigate = useNavigate();

    useEffect(() => {
        if (!userLocation && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;
                    setUserLocation([longitude, latitude]);
                    const address = await fetchAddress(longitude, latitude);
                    setLocationAddress(address);
                },
                async (error) => {
                    console.error('Error getting location:', error);
                    const defaultCoords: [number, number] = [74.3548, 31.5497];
                    setUserLocation(defaultCoords);
                    const fallbackAddress = await fetchAddress(...defaultCoords);
                    setLocationAddress(fallbackAddress);
                }
            );
        }
    }, [coordinates]);

    useEffect(() => {
        if (userLocation && mapContainer.current) {
            const mapInstance = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/streets-v11',
                center: userLocation,
                zoom: 15,
            });

            mapInstance.on('dragstart', () => setButtonEnabled(false));
            mapInstance.on('dragend', async () => {
                const center = mapInstance.getCenter();
                setButtonEnabled(true);
                const address = await fetchAddress(center.lng, center.lat);
                setLocationAddress(address);
                setSuggestions([]);
            });

            setMap(mapInstance);
        }
    }, [userLocation]);

    const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value;
        setLocationAddress(query);

        if (query.length > 1) {
            try {
                const response = await fetch(
                    `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(query)}.json?access_token=${mapboxgl.accessToken}`
                );
                const data = await response.json();
                const fetchedSuggestions = data.features.map((feature: any) => feature.place_name);
                setSuggestions(fetchedSuggestions);
                setShowSuggestions(true);
                setButtonEnabled(fetchedSuggestions.length > 0);
            } catch (error) {
                console.error('Error fetching autocomplete suggestions:', error);
            }
        } else {
            setSuggestions([]);
            setShowSuggestions(false);
            setButtonEnabled(false);
        }
    };

    const selectSuggestion = (address: string) => {
        setLocationAddress(address);
        setShowSuggestions(false);
        setSuggestions([]);
        setButtonEnabled(true);
    };

    const clearAddress = () => {
        setLocationAddress('');
        setButtonEnabled(false);
        setSuggestions([]);
        setShowSuggestions(false);
    };

    const handleConfirmLocation = () => {
        const [lng, lat] = map?.getCenter()?.toArray() || [0, 0];
        
        // Save location to local storage
        localStorage.setItem('coordinates', JSON.stringify([lng, lat]));
        localStorage.setItem('address', locationAddress);

        navigate(`/restaurant?lat=${lat}&lng=${lng}`);
        onClose();
    };

    return (
        <>
            <div className="overlay" onClick={onClose}></div>
            <div className="location-finder-modal">
                <div className="header">
                    <h2>What’s your exact location?</h2>
                </div>

                <div className="input-container">
                    <input
                        type="text"
                        value={locationAddress}
                        onChange={handleInputChange}
                        placeholder="Your street and street number"
                        readOnly={loading}
                    />
                    <button className="clear-button" onClick={clearAddress}>
                        <FaTimes />
                    </button>
                </div>

                {showSuggestions && suggestions.length > 0 && (
                    <ul className="suggestions-list">
                        {suggestions.map((suggestion, index) => (
                            <li
                                key={index}
                                onClick={() => selectSuggestion(suggestion)}
                                className="suggestion-item"
                            >
                                {suggestion}
                            </li>
                        ))}
                    </ul>
                )}

                <div ref={mapContainer} className="map-container">
                    <div className="center-pin" />
                </div>

                <button
                    className="find-button"
                    onClick={handleConfirmLocation}
                    disabled={!buttonEnabled}
                >
                    Find Restaurants
                </button>
            </div>
        </>
    );
};

export default LocationFinder;
