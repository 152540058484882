import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { getCartItems, sendOrder_Checkout } from "../../Common/api/restaurants/Restaurants";
import "./OrderList.css";
import Loader from '../../components/Loader/Loader';  
import { CartItem } from "../../Interface/CartItem";

interface OrderListProps {
  latitude: number;
  longitude: number;
  selectedAddress: string; // Address prop
}

function OrderList({ latitude, longitude, selectedAddress }: OrderListProps) {
  const location = useLocation();
  const { cartItems } = location.state as { cartItems: CartItem[] };
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState<CartItem[]>([]);
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchOrderData = async () => {
      const restaurantId = 1; // Replace with dynamic ID if needed
      const fetchedData = await getCartItems(restaurantId);
      setOrderData(fetchedData);
    };

    fetchOrderData();
  }, []);

  const subtotal = cartItems.reduce(
    (acc: number, item: CartItem) =>
      acc + (item.variantDetails?.price || item.item_price) * item.cartQuantity,
    0
  );

  const deliveryFee = 79;
  const serviceFee = 9.99;
  const vat = 0;
  const total = subtotal + deliveryFee + serviceFee + vat;

  // Click handler for the "Next" button
  const handleCheckout = async () => {
    setLoading(true); // Start loading

    // Retrieve user_id from localStorage
    const userId = localStorage.getItem('userId');

    if (!userId) {
      alert("User not logged in.");
      setLoading(false);
      return;
    }

    const orderPayload = {
      user_id: userId,  // Use userId from localStorage
      restaurant_id: 1,
      shipping_lat: latitude,
      shipping_lot: longitude,
      shipping_address: selectedAddress,
      specialInstruction: "Please ring the doorbell.",
      OrderType:1
    };

    try {
      const response = await sendOrder_Checkout(orderPayload);
      console.log("Order sent successfully:", response);
      navigate('/tracking', { state: { orderNumber: response.OrderNumber } });
    } catch (error) {
      console.error("Error sending order:", error);
      alert("Failed to place order. Please try again.");
    }
    finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="order-summary">
      <h3>Your order from</h3>
      <p className="restaurant-name">Bushra's Spice Hub</p>
      
      {/* Display selected address */}
      <div className="order-address">
        <span><strong>Delivery Address:</strong> {selectedAddress}</span>
      </div>

      <div className="order-items">
        {cartItems.map((item, index) => (
          <div key={index} className="order-item">
            <div className="item-details">
              <span>{item.cartQuantity} x {item.item_name}</span>
            </div>
            <span className="item-price">
              Rs. {(item.variantDetails?.price || item.item_price).toFixed(2)}
            </span>
          </div>
        ))}
      </div>
      
      <div className="order-summary-footer">
        <div className="order-cost">
          <span>Subtotal</span>
          <span>Rs. {subtotal.toFixed(2)}</span>
        </div>
        <div className="order-cost">
          <span>Standard delivery</span>
          <span>Rs. {deliveryFee}</span>
        </div>
        <div className="order-cost">
          <span>Service fee</span>
          <span>Rs. {serviceFee}</span>
        </div>
        <div className="order-cost">
          <span>VAT</span>
          <span>Rs. {vat}</span>
        </div>
      </div>
      
      <div className="order-total">
        <strong>Total</strong>
        <strong>Rs. {total.toFixed(2)}</strong>
      </div>

      <button className="save-button" onClick={handleCheckout}>
        {loading ? <Loader loading={loading} color="#ffffff" /> : 'Next'}
      </button>
    </div>
  );
}

export default OrderList;
