import React, { useState } from 'react';
import './Payment.css';

const Payments = () => {
    const [paymentMethod, setPaymentMethod] = useState('cash');

    return (
        <div className="payment">
            <h4>Payment</h4>
            <label className="radio-label">
    <input
        type="radio"
        value="cash"
        checked={paymentMethod === 'cash'}
        onChange={() => setPaymentMethod('cash')}
        className="radio-input"
    />
    <span className="radio-custom"></span>
    <span className="radio-text">Cash on Delivery</span>
</label>
<p>Simply pay the driver when they deliver the food to your doorstep.</p>

<label className="radio-label">
    <input
        type="radio"
        value="credit"
        checked={paymentMethod === 'credit'}
        onChange={() => setPaymentMethod('credit')}
        className="radio-input"
    />
    <span className="radio-custom"></span>
    <span className="radio-text">Credit Card</span>
</label>

            <button>Show All</button>
        </div>
    );
};

export default Payments;
