// src/components/Checkout/DeliveryAddressAndOptions.tsx

import React, { useState, useEffect } from 'react';
import Map, { Marker } from 'react-map-gl';
import { FaEdit } from 'react-icons/fa'; // Import the FaEdit icon
import 'mapbox-gl/dist/mapbox-gl.css';
import './DeliveryAddressAndOptions.css';
import Popup from '../Popup/Popup';
import LocationFinder from '../LocationFinder/LocationFinder';

interface SearchBarProps {
    onOpenPopup: () => void;
    searchInput: string;
    setSearchInput: (input: string) => void;
}

const DeliveryAddressAndOptions: React.FC<SearchBarProps> = ({
    onOpenPopup,
    searchInput,
    setSearchInput,
}) => {
    const initialLocation = {
        latitude: 31.5204,
        longitude: 74.3587,
    };

    const [address, setAddress] = useState({
        street: '',
        floor: '',
        note: '',
    });

    const [selectedAddress, setSelectedAddress] = useState('');
    const [isFindFoodPopupOpen, setFindFoodPopupOpen] = useState(false);
    const [deliveryOption, setDeliveryOption] = useState('standard');

    // Function to reverse geocode and get the location name based on fixed coordinates
    const reverseGeocode = async () => {
        const apiKey = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
        const response = await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${initialLocation.longitude},${initialLocation.latitude}.json?access_token=${apiKey}`
        );
        const data = await response.json();
        const placeName = data.features[0]?.place_name || 'Unknown Location';
        setSelectedAddress(placeName);
    };

    useEffect(() => {
        reverseGeocode();
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setAddress({ ...address, [name]: value });
    };

    const openPopup = () => {
        setFindFoodPopupOpen(true);
    };

    const closePopup = () => {
        setFindFoodPopupOpen(false);
    };

    return (
        <>
            <div className="delivery-address">
                <h3>Delivery Address</h3>

                <Map
                    initialViewState={{
                        latitude: initialLocation.latitude,
                        longitude: initialLocation.longitude,
                        zoom: 13,
                    }}
                    style={{ width: '100%', height: 200 }}
                    mapStyle="mapbox://styles/mapbox/streets-v11"
                    mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                    dragPan={false}
                    scrollZoom={false}
                    doubleClickZoom={false}
                >
                    <Marker
                        latitude={initialLocation.latitude}
                        longitude={initialLocation.longitude}
                        color="black"
                    />
                </Map>

                <button className="edit-button" onClick={openPopup}>
                    <FaEdit style={{ marginRight: '8px' }} /> Edit
                </button>

                <input
                    type="text"
                    name="selectedAddress"
                    placeholder="Selected Address"
                    value={selectedAddress}
                    readOnly
                />
                <input
                    type="text"
                    name="street"
                    placeholder="Street"
                    value={address.street}
                    onChange={handleInputChange}
                />
                <input
                    type="text"
                    name="floor"
                    placeholder="Floor"
                    value={address.floor}
                    onChange={handleInputChange}
                />
                <input
                    type="text"
                    name="note"
                    placeholder="Note to rider - e.g. building, landmark"
                    value={address.note}
                    onChange={handleInputChange}
                />

                <div className="label-buttons">
                    <button>Home</button>
                    <button>Work</button>
                    <button>Partner</button>
                    <button>Other</button>
                </div>
                <button className="save-button">Save and continue</button>

                <h4>Delivery Options</h4>
                <label className="radio-label">
                    <input
                        type="radio"
                        value="standard"
                        checked={deliveryOption === 'standard'}
                        onChange={() => setDeliveryOption('standard')}
                        className="radio-input"
                    />
                    <span className="radio-custom"></span>
                    <span className="radio-text">Standard 30 - 45 mins</span>
                </label>

                <label className="radio-label">
                    <input
                        type="radio"
                        value="express"
                        checked={deliveryOption === 'express'}
                        onChange={() => setDeliveryOption('express')}
                        className="radio-input"
                    />
                    <span className="radio-custom"></span>
                    <span className="radio-text">Express 15 - 30 mins</span>
                </label>
            </div>

            {isFindFoodPopupOpen && (
                <Popup isOpen={isFindFoodPopupOpen} onClose={closePopup}>
                    <LocationFinder onClose={closePopup} />
                </Popup>
            )}
        </>
    );
};

export default DeliveryAddressAndOptions;
