import ApiBaseUrl from '../../ApiBaseURL';
const token = localStorage.getItem('authToken');


// Helper function to handle API responses
const handleResponse = async (response: Response) => {
    if (!response.ok) {
        const errorText = await response.text(); // Get the response as text
        console.error(`Error response from API: ${errorText}`); // Log the raw response for debugging
        throw new Error(`Error ${response.status}: ${errorText || response.statusText}`);
    }
    return response.json(); // If response is okay, parse as JSON
};


const apiFetch = async (endpoint: string, options: RequestInit = {}) => {
   
    const headers = {
        'Content-Type': 'application/json',
        ...(token && { 'Authorization': `Bearer ${token}` }),
        ...options.headers,
    };
    const response = await fetch(`${ApiBaseUrl.apiBaseUrl}${endpoint}`, { ...options, headers });
    return handleResponse(response);
};

export default apiFetch;


// Login API
export const login = async (model: { email: string; password: string }) => {
    debugger;
    console.log("Logging in:", model);
    const data = await apiFetch('/Authenticate/login', {
        method: 'POST',
        body: JSON.stringify(model),
    });
    // Store token data
    if (data.token) {
        localStorage.setItem('authToken', data.token);
        localStorage.setItem('tokenExpiration', data.expiration);
        localStorage.setItem('userName', data.userName);
        localStorage.setItem('userId', data.userId);
    }
    return data;
};

// Register API
export const register = async (model: { Email: string; Password: string; FirstName: string; LastName: string }) => {
    console.log("Registering user:", model);
    return apiFetch('/Authenticate/Register', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(model),
    });
};


// Forgot Password API
export const forgotPassword = async (email: string) => {
    try {
        console.log("Requesting password reset for:", email);
        const response = await apiFetch('/Authenticate/ForgotPasswordWithCode', {
            method: 'POST',
            body: JSON.stringify({ Email: email }),
        });
        return response; 
    } catch (error) {
        console.error(`Failed to send password reset email: ${error}`);
        throw new Error(`Failed to send password reset email: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
};

// Reset Password API
export const resetPassword = async (email: string, code: string, newPassword: string): Promise<boolean> => {
    console.log("Resetting password for:", email);
    await apiFetch('/Authenticate/ResetPasswordWithCode', {
        method: 'POST',
        body: JSON.stringify({ email, code, newPassword }),
    });
    return true;
};

// Change Password API
export const changePassword = async (model: { OldPassword: string; NewPassword: string }) => {
    if (!token) throw new Error('No authentication token found');
    console.log("Changing password for user");
    await apiFetch('/Authenticate/ChangePassword', {
        method: 'POST',
        body: JSON.stringify(model),
    });
    return true;
};

// Get Country List API
export const getCountryList = async (countryId: number) => {
    console.log("Fetching country list for ID:", countryId);
    try {
        return await apiFetch(`/Country/GetAllCountries?countryId=${countryId}`, {
            method: 'GET',
        });
    } catch {
        return [];
    }
};

// Get All Users API
export interface GetAllUsersParams {
    IsActive?: boolean;
    CountryId?: string;
    IsEmailConfirmed?: boolean;
    Role?: string;
    SearchTerm?: string;
    pageNumber?: number;
    pageSize?: number;
}

export const getAllUsers = async (params: GetAllUsersParams) => {
    const queryString = new URLSearchParams({
        IsActive: params.IsActive?.toString() || 'true',
        CountryId: params.CountryId || '',
        IsEmailConfirmed: params.IsEmailConfirmed?.toString() || 'false',
        Role: params.Role || '',
        SearchTerm: params.SearchTerm || '',
        pageNumber: params.pageNumber?.toString() || '1',
        pageSize: params.pageSize?.toString() || '10',
    }).toString();

    console.log("Fetching all users with params:", queryString);
    const data = await apiFetch(`/Authenticate/GetAllUsers?${queryString}`, {
        method: 'GET',
    });

    return {
        users: data.users || [],
        totalRows: data.totalRows || 0,
    };
};

// Logout function
export const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('tokenExpiration');
    window.location.href = '/';
};
