// src/components/Alert_Message/AlertMessage.tsx
import React from 'react';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

interface AlertMessageProps {
    severity: 'success' | 'info' | 'warning' | 'error';
    message: string;
    onClose: () => void;
}

const AlertMessage: React.FC<AlertMessageProps> = ({ severity, message, onClose }) => {
    return (
        <Alert 
            severity={severity} 
            action={
                <IconButton aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            }
        >
            {message}
        </Alert>
    );
};

export default AlertMessage;
