import React, { useState, useEffect } from 'react';
import './Header.css';
import SearchBar from './SearchBar';
import LocationFinder from '../LocationFinder/LocationFinder';
import Popup from '../Popup/Popup';
import Navbar from '../Navbar/Navbar';

interface HeaderProps {
    openLoginPopup: () => void;
}

const Header: React.FC<HeaderProps> = ({ openLoginPopup }) => {
    const [isFindFoodPopupOpen, setFindFoodPopupOpen] = useState(false);
    const [searchVisible, setSearchVisible] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [coordinates, setCoordinates] = useState<[number, number] | undefined>();

    const closePopup = () => {
        setFindFoodPopupOpen(false);
    };

    const openFindFoodPopup = (coords?: [number, number]) => {
        setCoordinates(coords);
        setFindFoodPopupOpen(true);
    };

    const handleScroll = () => {
        setSearchVisible(window.scrollY >= 50);
    };

    const openLocationPopup = () => {
        // Logic to open location popup
        setFindFoodPopupOpen(true);
    };
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <Navbar 
                openLoginPopup={openLoginPopup} 
                searchVisible={searchVisible} 
                onOpenPopup={() => openFindFoodPopup(coordinates)} 
                searchInput={searchInput}
                setSearchInput={setSearchInput}
            />
            <div id="main">
                <div className="header-heading">
                    {!searchVisible && (
                        <SearchBar 
                            onOpenPopup={openFindFoodPopup} 
                            searchInput={searchInput} 
                            setSearchInput={setSearchInput}
                        />
                    )}
                </div>
            </div>

            <Popup isOpen={isFindFoodPopupOpen} onClose={closePopup}>
                {/* Pass searchInput as initialLocationAddress */}
                <LocationFinder onClose={closePopup} coordinates={coordinates} initialLocationAddress={searchInput} />
            </Popup>
        </>
    );
};

export default Header;
