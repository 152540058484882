import React from 'react';
import { FaEdit } from 'react-icons/fa'; // Import the edit icon from React Icons
import './PersonalDetails.css';

const PersonalDetails = () => {
    return (
        <div className="personal-details">
            <h4>Personal Details</h4>
            <p>Muhammad Azeem</p>
            <p>ma2725481@gmail.com</p>
            <p>+92 3480188158</p>
            <button>
                <FaEdit style={{ marginRight: '8px' }} /> Edit {/* Icon with text */}
            </button>
        </div>
    );
};

export default PersonalDetails;
