import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Outlet } from 'react-router-dom';
import Popup from '../../components/Popup/Popup';
import LoginPage from '../Login/LoginPage';
import RegisterPage from '../../components/Register/RegisterPage';
import ForgotPassword from '../../components/ForgotPassword/ForgotPassword';

const SharedLayout: React.FC = () => {
    const [isLoginPopupOpen, setLoginPopupOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState<'login' | 'register' | 'forgotPassword'>('login');
    const [searchInput, setSearchInput] = useState(''); // Add search input state
    const location = useLocation();

    const closeLoginPopup = () => {
        setLoginPopupOpen(false);
        setCurrentPage('login');
    };

    const openLoginPopup = () => {
        setCurrentPage('login');
        setLoginPopupOpen(true);
    };

    const openRegisterPopup = () => {
        setCurrentPage('register');
        setLoginPopupOpen(true);
    };

    const openForgotPasswordPopup = () => {
        setCurrentPage('forgotPassword');
        setLoginPopupOpen(true);
    };

    // Check if the current route is the home page
    const isHomePage = location.pathname === '/';

    return (
        <div className="App">
            <header>
                <Navbar 
                    openLoginPopup={openLoginPopup} 
                    searchVisible={!isHomePage} // Show SearchBar in Navbar if not on the home page
                    onOpenPopup={() => {}}  // Not used here for Find Food popup
                    searchInput={searchInput} // Pass search input state to Navbar
                    setSearchInput={setSearchInput} // Pass search input setter to Navbar
                />
                {isHomePage && <Header openLoginPopup={openLoginPopup} />} {/* Pass openLoginPopup to Header */}
            </header>
            <main>
                <Outlet />
            </main>
            <Popup isOpen={isLoginPopupOpen} onClose={closeLoginPopup}>
                {currentPage === 'forgotPassword' ? (
                    <ForgotPassword onClose={closeLoginPopup} onLoginClick={openLoginPopup} />
                ) : currentPage === 'register' ? (
                    <RegisterPage onClose={closeLoginPopup} onLoginClick={openLoginPopup} />
                ) : (
                    <LoginPage
                        onClose={closeLoginPopup}
                        onRegisterClick={openRegisterPopup}
                        onForgotPasswordClick={openForgotPasswordPopup}
                    />
                )}
            </Popup>
            <footer>
                <Footer />
            </footer>
        </div>
    );
};

export default SharedLayout;
