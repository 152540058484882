import React from 'react';

interface InputFieldProps {
    type: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder: string;
    label: string;
    required?: boolean;
}

const InputField: React.FC<InputFieldProps> = ({ type, value, onChange, placeholder, label, required }) => {
    return (
        <div className="floating-input">
            <input
                type={type}
                className="form-control"
                value={value}
                onChange={onChange}
                placeholder=" " // Trigger floating effect
                required={required}
            />
            <label>{label}</label>
        </div>
    );
};

export default InputField;
