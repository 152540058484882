// src/components/CategoryTab.tsx
import React, { useEffect, useRef } from 'react';

interface CategoryTabProps {
  categories: { categoryId: number; categoryName: string; items: any[] }[];
  activeCategory: number | null;
  onCategoryClick: (categoryId: number) => void;
  setActiveCategory: React.Dispatch<React.SetStateAction<number | null>>;
  sectionRefs: React.MutableRefObject<{ [key: number]: HTMLDivElement | null }>;
}

const CategoryTab: React.FC<CategoryTabProps> = ({
  categories,
  activeCategory,
  onCategoryClick,
  setActiveCategory,
  sectionRefs,
}) => {
  const handleScroll = () => {
    categories.forEach((category) => {
      const section = sectionRefs.current[category.categoryId];
      if (section) {
        const { top, bottom } = section.getBoundingClientRect();
        if (top <= 50 && bottom >= 50) {
          setActiveCategory(category.categoryId);
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [categories]);

  return (
    <div className="menu-categories">
      {categories.map((category) => (
        <span
          key={category.categoryId}
          className={`category-tab ${activeCategory === category.categoryId ? 'active' : ''}`}
          onClick={() => onCategoryClick(category.categoryId)}
        >
          {category.categoryName} ({category.items.length})
        </span>
      ))}
    </div>
  );
};

export default CategoryTab;
