// src/components/Restaurant/Details/RestaurantDetails.tsx
import React from 'react';
import './RestaurantDetails.css';
import PlaceholderImage from '../../../assets/Placeholder.jpeg'; // Path to your placeholder image

interface RestaurantDetailsProps {
  data: {
    restaurant_id: number;
    restaurant_name: string;
    short_description: string;
    cover_photo: string;
    city: string;
    area: string;
    minimumOrderPrice: number;
    fixedDeliveryFee: number;
    discount: number;
    deliveryAddress: string;
    averageRating: number;
  };
}

const RestaurantDetails: React.FC<RestaurantDetailsProps> = ({ data }) => {
  return (
    <div className="restaurant-card">
      <img
        src={data.cover_photo}
        alt={data.restaurant_name}
        className="restaurant-card-image"
        onError={(e) => (e.currentTarget.src = PlaceholderImage)}
      />

      <div className="restaurant-card-content">
        <div className="restaurant-breadcrumbs">
          {data.city} &gt; Restaurant List &gt; {data.restaurant_name}
        </div>
        <p className="restaurant-tags">{data.short_description}</p>
        <h2 className="restaurant-name">{data.restaurant_name}</h2>

        <div className="restaurant-meta">
          <span style={{ marginRight: '8px' }}>🚴 Free delivery</span>
          <span>💰Rs.{data.minimumOrderPrice} Minimum</span>
          <div className="restaurant-meta-details">
            <span style={{ marginRight: '8px' }}>⭐ {data.averageRating}/5 (500+)</span>
            <span className="link" style={{ marginRight: '8px' }}>See reviews</span>
            <span className="link">More info</span>
          </div>
        </div>

        <div className="restaurant-extra-info">
          {/* <p>Location: {data.city}, {data.area}</p>
          <p>Delivery Address: {data.deliveryAddress}</p> */}
        </div>
      </div>
    </div>
  );
};

export default RestaurantDetails;
