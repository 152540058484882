import React from 'react';
import { IRestaurant } from '../../../Interface/IRestaurant';
import { Link } from 'react-router-dom';
import PlaceholderImage from '../../../assets/Placeholder.jpeg'; // Import your placeholder image

interface RestaurantCardProps {
    restaurant: IRestaurant;
}

const RestaurantCard: React.FC<RestaurantCardProps> = ({ restaurant }) => {

    
    
    return (
        <Link 
        to={{
            pathname: `/restaurant/${restaurant.restaurant_id}/${restaurant.restaurant_name.replace(/\s+/g, '-').toLowerCase()}`,
            state: { lat: restaurant.lat, lot: restaurant.lot }
          } as unknown as Location}>
            <div className="collection-card shadow-sm">
            <img 
                    src={restaurant.cover_photo || PlaceholderImage} 
                    alt={restaurant.restaurant_name} 
                    className="card-img-top" 
                    onError={(e) => {
                        (e.target as HTMLImageElement).src = PlaceholderImage; // Fallback to custom placeholder
                    }}
                />
                <div className="collection-info p-3">
                    <h3 className="collection-title">{restaurant.restaurant_name}</h3>
                    <p className="collection-places">{restaurant.short_description}</p>
                    <p className="collection-places">Distance: {restaurant.distanceFormatted}</p>
                    <p className="collection-places">Rating: {restaurant.averageRating}</p>
                </div>
            </div>
        </Link>
    );
};

export default RestaurantCard;
