import React, { useState, useEffect } from 'react';
import PlaceholderImage from '../../assets/Placeholder.jpeg';
import './ItemDetailsModal.css';
import { addToCart, getItemWithChoicesAndToppingsById } from '../../Common/api/restaurants/Restaurants';
import Item from '../../Interface/IItemVarients';
import { useCart } from '../../Context/CartContext';

interface ItemDetailsModalProps {
  itemId: number;
  onClose: () => void;
}

const ItemDetailsModal: React.FC<ItemDetailsModalProps> = ({ itemId, onClose }) => {
  const { refreshCart } = useCart();
  const [item, setItem] = useState<Item | null>(null);
  const [selectedVariantIndex, setSelectedVariantIndex] = useState<number | null>(null);
  const [selectedToppings, setSelectedToppings] = useState<Map<number, Set<number>>>(new Map()); // Store toppings per group
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    const fetchItemDetails = async () => {
      try {
        setLoading(true);
        const data = await getItemWithChoicesAndToppingsById(itemId);
        setItem(data);
        // Reset state when item data is fetched
        setSelectedVariantIndex(null);
        setSelectedToppings(new Map());
      } catch (error) {
        console.error('Error fetching item details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchItemDetails();
  }, [itemId]);

  useEffect(() => {
    setIsButtonDisabled(checkRequiredSelections());
  }, [item, selectedVariantIndex, selectedToppings, quantity]);

  const checkRequiredSelections = (): boolean => {
    if (!item) return true; // If item is not loaded, disable the button

    // Check if the item requires a variant and if one is selected
    const isVariantRequired = item.isVarient && item.variants.length > 0;
    const isVariantNotSelected = isVariantRequired && selectedVariantIndex === null;

    // Check if the item has choices (toppings) and if the user has selected enough toppings
    let isToppingValid = true;
    item.choicesAddsOnsGroupList?.forEach((group) => {
      const selectedToppingsForGroup = selectedToppings.get(group.choicesAddsOnsGroupId) || new Set();
      const forceMin = group.forceMin || 0;
      const forceMax = group.forceMax || Infinity;

      if (selectedToppingsForGroup.size < forceMin || selectedToppingsForGroup.size > forceMax) {
        isToppingValid = false;
      }
    });

    return isVariantNotSelected || !isToppingValid;
  };

  const handleVariantChange = (index: number) => {
    setSelectedVariantIndex(index);
  };

  const handleToppingChange = (groupId: number, toppingId: number) => {
    setSelectedToppings((prev) => {
      const updatedToppings = new Map(prev);
      const selectedGroup = updatedToppings.get(groupId) || new Set();
      if (selectedGroup.has(toppingId)) {
        selectedGroup.delete(toppingId);
      } else {
        selectedGroup.add(toppingId);
      }
      updatedToppings.set(groupId, selectedGroup);
      return updatedToppings;
    });
  };

  const handleQuantityIncrease = () => {
    setQuantity((prev) => prev + 1);
  };

  const handleQuantityDecrease = () => {
    setQuantity((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const totalToppingPrice = Array.from(selectedToppings.values()).reduce((total, toppingsSet) => {
    return total + Array.from(toppingsSet).reduce((toppingTotal, toppingId) => {
      const topping = item?.choicesAddsOnsGroupList?.flatMap((group) => group.toppings).find((t) => t.toppingId === toppingId);
      return toppingTotal + (topping ? topping.toppingPrice : 0);
    }, 0);
  }, 0);

  const totalPrice = item ? item.item_price * quantity + (selectedVariantIndex !== null && item.variants
    ? item.variants[selectedVariantIndex].variantPrice * quantity
    : 0) + totalToppingPrice : 0;

  const handleAddToCart = async () => {
    if (selectedVariantIndex === null && item?.isVarient) return;

    const variant = item?.variants ? item.variants[selectedVariantIndex || 0] : null;
    const toppingsArray: number[] = [];
    selectedToppings.forEach((toppingsSet) => {
      toppingsArray.push(...Array.from(toppingsSet));
    });

    try {
      await addToCart(itemId, quantity, 1, variant ? selectedVariantIndex : null, toppingsArray);
      console.log('Item successfully added to cart');
      refreshCart();
      onClose();
    } catch (error) {
      console.error('Error adding item to cart:', error);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (!item) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>×</button>
        <div className="modal-body">
          <img
            src={item.imageFile || PlaceholderImage}
            alt={item.item_name}
            onError={(e) => (e.currentTarget.src = PlaceholderImage)}
            className="modal-item-image"
          />
          <div className="item-details">
            <div className="sticky-header">
              <h2>{item.item_name}</h2>
            </div>
            <p>Price from Rs. {item.item_price}</p>
            <p>{item.item_description}</p>

            {item.variants && item.variants.length > 0 && (
              <div className="variants">
                <h3>Choose a Variant</h3>
                {item.variants.map((variant, index) => (
                  <div key={index} className="variant-option">
                    <label>
                      <input
                        type="radio"
                        name="variant"
                        checked={selectedVariantIndex === index}
                        onChange={() => handleVariantChange(index)}
                      />
                      {variant.variantName} (+Rs. {variant.variantPrice})
                    </label>
                  </div>
                ))}
              </div>
            )}

            {item.choicesAddsOnsGroupList?.map((group, index) => (
              <div key={group.choicesAddsOnsGroupId} className="toppings">
                <h3>{group.groupName}</h3>
                {group.toppings.map((topping) => (
                  <div key={topping.toppingId} className="topping-option">
                    <label className="topping-label">
                      <input
                        type="checkbox"
                        checked={selectedToppings.get(group.choicesAddsOnsGroupId)?.has(topping.toppingId) || false}
                        onChange={() => handleToppingChange(group.choicesAddsOnsGroupId, topping.toppingId)}
                        className="topping-checkbox"
                      />
                      <span className="topping-name">{topping.toppingName}</span>
                      <span className="topping-price">+Rs. {topping.toppingPrice}</span>
                    </label>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>

        <div className="quantity-control-container">
          <div className="quantity-control">
            <button className="quantity-button" onClick={handleQuantityDecrease}>−</button>
            <span className="quantity-display">{quantity}</span>
            <button className="quantity-button" onClick={handleQuantityIncrease}>+</button>
          </div>

          <button
            className="add-to-order-button"
            disabled={isButtonDisabled}
            onClick={handleAddToCart}
          >
            Add to order • Rs. {totalPrice}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ItemDetailsModal;
