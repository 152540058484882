import React from "react";
import Order from "../../components/Order/Orderonline&dinning";
import Collections from "../../components/Collections/Collections";
import About from "../../components/About/About";
import Contact from "../../components/Contact/Contact";
import Products from "../../components/Products/Products";

// Define the function to handle opening the popup
const HomePage = () => {
    const handleOpenPopup = (coordinates?: [number, number]) => {
        // Logic to open the popup
        if (coordinates) {
            console.log("Opening popup with coordinates:", coordinates);
        } else {
            console.log("Opening popup without coordinates");
        }
    };

    return (
        <div>
            {/* Pass the handleOpenPopup function as a prop to Order */}
            <Order onOpenPopup={handleOpenPopup} />
            <Collections />
            {/* <Products />
            <About /> */}
            <Contact />
        </div>
    );
};

export default HomePage;
