// src/pages/checkout/CheckoutPage.tsx

import React, { useState } from "react";
import OrderList from "../../components/Checkout/OrderList";
import DeliveryAddressAndOptions from "../../components/Checkout/DeliveryAddressAndOptions";
import PersonalDetails from "../../components/Checkout/PersonalDetails";
import Payments from "../../components/Checkout/Payment";
import './checkoutPage.css';

function CheckoutPage() {
  const [searchInput, setSearchInput] = useState("");
  const [latitude, setLatitude] = useState<number>(31.5204);
  const [longitude, setLongitude] = useState<number>(74.3587);
  const [selectedAddress, setSelectedAddress] = useState<string>("");

  const handleLocationUpdate = (lat: number, lng: number, address: string) => {
    setLatitude(lat);
    setLongitude(lng);
    setSelectedAddress(address);
  };

  const onOpenPopup = () => {
    console.log("Popup opened");
  };

  return (
    <div className="app-container">
      <div className="details-container">
        <DeliveryAddressAndOptions
          onOpenPopup={onOpenPopup}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
        <PersonalDetails />
        <Payments />
      </div>
      <div className="order-list-container">
        <OrderList 
          latitude={latitude}
          longitude={longitude}
          selectedAddress={selectedAddress}
        />
      </div>
    </div>
  );
}

export default CheckoutPage;
