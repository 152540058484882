import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './RestaurantMapDistance.css'; // Import the CSS file

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || '';

interface MapPropsRestaurantMapDistance {
    userCoordinates: [number, number];  // [longitude, latitude]
    restaurantCoordinates: [number, number];
}

const RestaurantMapDistance: React.FC<MapPropsRestaurantMapDistance> = ({ userCoordinates, restaurantCoordinates }) => {
    const mapContainerRef = useRef<HTMLDivElement | null>(null);
    const distance = calculateDistance(userCoordinates, restaurantCoordinates);

    useEffect(() => {
        if (mapContainerRef.current) {
            const map = new mapboxgl.Map({
                container: mapContainerRef.current,
                style: 'mapbox://styles/mapbox/streets-v11',
                center: userCoordinates,
                zoom: 10,
                interactive: false,
            });

            new mapboxgl.Marker({ color: 'black' })
                .setLngLat(userCoordinates)
                .addTo(map);

            new mapboxgl.Marker({ color: 'red' })
                .setLngLat(restaurantCoordinates)
                .addTo(map);

            map.on('load', () => {
                map.addSource('route', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: [
                            {
                                type: 'Feature',
                                geometry: {
                                    type: 'LineString',
                                    coordinates: [userCoordinates, restaurantCoordinates],
                                },
                                properties: {},
                            },
                        ],
                    },
                });

                map.addLayer({
                    id: 'route',
                    type: 'line',
                    source: 'route',
                    layout: {
                        'line-join': 'round',
                        'line-cap': 'round',
                    },
                    paint: {
                        'line-color': '#888',
                        'line-width': 4,
                    },
                });

                const bounds = new mapboxgl.LngLatBounds();
                bounds.extend(userCoordinates);
                bounds.extend(restaurantCoordinates);
                map.fitBounds(bounds, { padding: 20 });
            });
        }
    }, [userCoordinates, restaurantCoordinates]);

    function calculateDistance([lon1, lat1]: [number, number], [lon2, lat2]: [number, number]) {
        const toRad = (x: number) => (x * Math.PI) / 180;
        const R = 6371;
        const dLat = toRad(lat2 - lat1);
        const dLon = toRad(lon2 - lon1);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return (R * c * 0.621371).toFixed(1);
    }

    return (
        <div className="card-container">
            <div ref={mapContainerRef} className="map-container-distance"></div>
            <div className="card-details">
                <h2>175-177 High Street</h2>
                <p>Elgin, EMEA</p>
                <p><strong>Open</strong> until 11:00 PM</p>
                <p>Distance: {distance} miles</p>
            </div>
        </div>
    );
};

export default RestaurantMapDistance;
