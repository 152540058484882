import React, { useEffect, useState } from 'react';
import './trackingPage.css';
import { useLocation } from 'react-router-dom';

interface trackingPageProps {
  orderNumber: number;
}

const TrackingPage: React.FC = () => {
  const [estimatedDeliveryTime, setEstimatedDeliveryTime] = useState<string>('');
  const [status, setStatus] = useState<string>('Preparing your order');

  const location = useLocation();
  const { orderNumber } = location.state as { orderNumber: number };
  
  useEffect(() => {
    // Example of dynamically setting the estimated time (could be updated based on API in real implementation)
    const deliveryTime = new Date();
    deliveryTime.setMinutes(deliveryTime.getMinutes() + 30); // Estimated time 30 mins from now
    setEstimatedDeliveryTime(deliveryTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
    
    // Example to simulate tracking status updates
    const statusUpdates = ['Preparing your order', 'Out for delivery', 'Arriving soon'];
    let index = 0;
    const intervalId = setInterval(() => {
      setStatus(statusUpdates[index]);
      index = (index + 1) % statusUpdates.length;
    }, 10000); // Update every 10 seconds

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="tracking-page">
      <div className="tracking-card">
        <h2>Thank you for your order!</h2>
        <p>Your order number is <strong>#{orderNumber}</strong></p>

        <div className="tracking-status">
          <h3>Order Status</h3>
          <div className="status-indicator">
            <span>{status}</span>
          </div>
        </div>

        <div className="delivery-time">
          <h3>Estimated Delivery Time</h3>
          <p>{estimatedDeliveryTime}</p>
        </div>

        <button className="track-button" onClick={() => window.location.href = '/'}>
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default TrackingPage;
