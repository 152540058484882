// src/components/Menu/RestaurantMenu.tsx
import React from 'react';
import './RestaurantMenu.css';
import PlaceholderImage from '../../assets/Placeholder.jpeg';

interface RestaurantMenuProps {
  categories: { categoryId: number; categoryName: string; items: any[] }[];
  sectionRefs: React.MutableRefObject<{ [key: number]: HTMLDivElement | null }>;
  handleItemClick: (item: number) => void;
}

const RestaurantMenu: React.FC<RestaurantMenuProps> = ({ categories, sectionRefs, handleItemClick }) => {
  return (
   
    <>
   
      {categories.map((category) => (
        <div
          className="menu-section"
          key={category.categoryId}
          ref={(el) => (sectionRefs.current[category.categoryId] = el)}
        >
          <h2 className="section-title">{category.categoryName}</h2>
          <div className="menu-grid">
            {category.items.map((item) => (
              <div
                className="menu-card"
                key={item.itemId}
                onClick={() => handleItemClick(item.itemId)}
              >
                <div className="menu-card-content">
                  <h3>{item.itemName}</h3>
                  <p>from Rs. {item.itemPrice}</p>
                </div>
                <div className="menu-card-image">
                  <img
                    src={item.largeImage || PlaceholderImage}
                    alt={item.itemName}
                    onError={(e) => (e.currentTarget.src = PlaceholderImage)}
                  />
                  <button className="add-button">+</button>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default RestaurantMenu;
