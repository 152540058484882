import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationCrosshairs } from '@fortawesome/free-solid-svg-icons';
import './SearchBar.css';
import { fetchAddress } from '../../Common/utils/mapbox/geolocationUtils';

interface SearchBarProps {
    onOpenPopup: (coordinates?: [number, number]) => void;
    searchInput: string;
    setSearchInput: (input: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onOpenPopup, searchInput, setSearchInput }) => {
    const [coordinates, setCoordinates] = useState<[number, number] | null>(null);

    // Load location from local storage on component mount
    useEffect(() => {
        const savedCoordinates = localStorage.getItem('coordinates');
        const savedAddress = localStorage.getItem('address');

        if (savedCoordinates && savedAddress) {
            setCoordinates(JSON.parse(savedCoordinates) as [number, number]);
            setSearchInput(savedAddress);
        }
    }, [setSearchInput]);

    const handleCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;
                    const address = await fetchAddress(longitude, latitude);
                    setSearchInput(address);
                    setCoordinates([longitude, latitude]);

                    // Save to local storage
                    localStorage.setItem('coordinates', JSON.stringify([longitude, latitude]));
                    localStorage.setItem('address', address);
                },
                (error) => {
                    console.error('Error fetching location:', error);
                    setSearchInput("Unable to retrieve location");
                }
            );
        } else {
            alert('Geolocation is not supported by this browser.');
        }
    };

    return (
        <div className="search-container">
            <div className="location-input-wrapper">
                <input
                    type="text"
                    className="location-input"
                    placeholder="Your street and street number"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                />
                <button className="locate-me-btn" onClick={handleCurrentLocation}>
                    <FontAwesomeIcon icon={faLocationCrosshairs} className="locate-icon" />
                    Locate me
                </button>
            </div>
            <button onClick={() => onOpenPopup(coordinates || undefined)} className="find-food-btn">
                Find food
            </button>
        </div>
    );
};

export default SearchBar;
