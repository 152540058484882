import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { getRestaurants } from "../../Common/api/restaurants/Restaurants";
import RestaurantCard from "../../components/Restaurant/Card/RestaurantCard";
import ShimmerRestaurantCard from "../../components/Shimmer/ShimmerCard";
import { IRestaurant } from "../../Interface/IRestaurant";
import { FiFilter } from "react-icons/fi"; // Import filter icon
import AdvanceFilter from "../../components/Restaurant/Filters/AdvanceFilter"; // Import the AdvanceFilter component

function RestaurantPage() {
    const [searchParams] = useSearchParams();
    const lat = parseFloat(searchParams.get("lat") || "0");
    const lng = parseFloat(searchParams.get("lng") || "0");
    const [restaurants, setRestaurants] = useState<IRestaurant[]>([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [isFilterOpen, setIsFilterOpen] = useState(false); // State to control the filter modal

    useEffect(() => {
        const fetchRestaurants = async () => {
            setLoading(true);
            try {
                const data = await getRestaurants({
                    currentLat: lat,
                    currentLon: lng,
                    distanceKm: 5,
                    page,
                    pageSize: 4,
                });
                setRestaurants((prevRestaurants) => [
                    ...prevRestaurants,
                    ...data.restaurants,
                ]);
            } catch (error) {
                console.error("Error fetching restaurants:", error);
            } finally {
                setLoading(false);
            }
        };

        if (lat && lng) {
            fetchRestaurants();
        }
    }, [lat, lng, page]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollHeight = document.documentElement.scrollHeight;
            const offsetHeight = window.innerHeight + window.scrollY;

            if (offsetHeight >= scrollHeight - 50 && !loading) {
                setPage((prevPage) => prevPage + 1);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [loading]);

    return (
        <div className="container mt-5">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h2 className="text-left">Restaurants Near You</h2>
                <button
                    className="btn btn-outline-secondary d-flex align-items-center justify-content-center p-1"
                    style={{
                        fontSize: "1rem",
                        padding: "4px 8px",
                        borderRadius: "15px",
                        width: "auto",
                        minWidth: "80px",
                    }}
                    onClick={() => setIsFilterOpen(true)} // Open filter modal
                >
                    <span>Filter</span>
                    <FiFilter style={{ fontSize: "1rem", marginLeft: "5px" }} />
                </button>
            </div>
            <p className="text-left">
                Discover the best places to eat in your area based on your preferences.
            </p>
            <div className="row">
                {loading && restaurants.length === 0
                    ? Array.from({ length: 4 }).map((_, index) => (
                          <ShimmerRestaurantCard key={index} />
                      ))
                    : restaurants.map((restaurant) => (
                          <div
                              className="col-md-3 col-sm-6 mb-4"
                              key={restaurant.restaurant_id}
                          >
                              <div className="collection-card shadow-sm">
                                  <RestaurantCard restaurant={restaurant} />
                              </div>
                          </div>
                      ))}
            </div>
            {loading && restaurants.length > 0 && (
                <div className="row">
                    {Array.from({ length: 4 }).map((_, index) => (
                        <ShimmerRestaurantCard key={index} />
                    ))}
                </div>
            )}

            {isFilterOpen && <AdvanceFilter onClose={() => setIsFilterOpen(false)} />} {/* Render the filter modal */}
        </div>
    );
}

export default RestaurantPage;
