// src/components/ShimmerCard.tsx
import React from 'react';
import './ShimmerCard.css'; // Import your shimmer card styles

const ShimmerCard: React.FC = () => {
    return (
        <div className="shimmer-card col-md-3 col-sm-6 mb-4">
            <div className="collection-card shadow-sm">
                <div className="shimmer-image"></div>
                <div className="collection-info p-3">
                    <div className="shimmer-title"></div>
                    <div className="shimmer-places"></div>
                </div>
            </div>
        </div>
    );
};

export default ShimmerCard;
