import React, { createContext, useState, useContext, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";

interface TokenPayload {
    exp: number;
    given_name: string;
    email: string;
    sid: string;
    jti: string;
}

interface UserContextType {
    isLoggedIn: boolean;
    setIsLoggedIn: (status: boolean) => void;
    userInfo: { name: string; email: string; photoUrl: string | null } | null;
    setUserInfo: (info: { name: string; email: string; photoUrl: string | null }) => void;
    checkTokenValidity: () => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userInfo, setUserInfo] = useState<{ name: string; email: string; photoUrl: string | null } | null>(null);

    const checkTokenValidity = () => {
        const token = localStorage.getItem("authToken");

        if (token) {
            try {
                const decoded = jwtDecode<TokenPayload>(token);
                const { exp, given_name, email } = decoded;

                // Check if the token is expired
                if (Date.now() >= exp * 1000) {
                    setIsLoggedIn(false);
                    setUserInfo(null);
                    localStorage.removeItem("token"); // Clear the expired token
                } else {
                    // If token is valid, update state
                    setIsLoggedIn(true);
                    setUserInfo({
                        name: given_name,
                        email,
                        photoUrl: `https://via.placeholder.com/40` // Placeholder for profile image
                    });
                }
            } catch (error) {
                console.error("Error decoding token:", error);
                setIsLoggedIn(false);
                setUserInfo(null);
                localStorage.removeItem("token");
            }
        } else {
            setIsLoggedIn(false); // No token, not logged in
            setUserInfo(null);
        }
    };

    // Check token validity on initial load (only once)
    useEffect(() => {
        checkTokenValidity();
    }, []); // Empty dependency array means it runs only once on component mount

    return (
        <UserContext.Provider value={{ isLoggedIn, setIsLoggedIn, userInfo, setUserInfo, checkTokenValidity }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUserContext = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUserContext must be used within a UserProvider');
    }
    return context;
};
