import React from 'react'
import './Contact.css';

const Contact = () => {
    return (
        <div id="contact">
            <h1>Book Your Table</h1>
            <form>
                <input type="text" placeholder="Full Name" />
                <input type="Email" placeholder="Email" />
                <textarea placeholder="Write Here"></textarea>
                <input type="submit" value="Book" />
            </form>
        </div>
    )
}

export default Contact
