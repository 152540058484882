import { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png';
import SearchBar from '../Header/SearchBar';
import './Navbar.css';
import { useUserContext } from '../../Context/UserContext';

interface NavbarProps {
    openLoginPopup: () => void;
    searchVisible: boolean;
    onOpenPopup: () => void;
    searchInput: string; // New prop for search input
    setSearchInput: (input: string) => void; // New prop to update search input

}

const Navbar: React.FC<NavbarProps> = ({ openLoginPopup, searchVisible, onOpenPopup, searchInput, setSearchInput, }) => {
    const { isLoggedIn, userInfo } = useUserContext();

    const [nav, setNav] = useState(false);

    const changeBackground = () => {
        if (window.scrollY >= 50) {
            setNav(true);
        } else {
            setNav(false);
        }
    };

    window.addEventListener('scroll', changeBackground);

    const placeholderPhoto = 'https://via.placeholder.com/40'; // Online placeholder image URL

    return (
        <nav className={nav ? 'nav active' : 'nav'}>

            <Link to="/" className="logo">
                <img src={logo} alt="sumbite" />
            </Link>

            {searchVisible && (
                <SearchBar
                    onOpenPopup={onOpenPopup}
                    searchInput={searchInput} // Pass searchInput to SearchBar
                    setSearchInput={setSearchInput} // Pass setSearchInput to SearchBar
                />
            )}
            {/* <input className="menu-btn" type="checkbox" id="menu-btn" /> */}
            {/* <label className="menu-icon" htmlFor="menu-btn">
                <span className="nav-icon"></span>
            </label> */}

            {isLoggedIn ? (
                <div className="user-info">
                    <img src={userInfo?.photoUrl || placeholderPhoto} alt="User" className="user-photo" />
                    <span>{userInfo?.name}</span>
                </div>
            ) : (

                <ul className="menu">
                    <li>
                        <a onClick={openLoginPopup} style={{ cursor: 'pointer' }}>Login</a>
                    </li>
                </ul>
            )}
        </nav>
    );
};

export default Navbar;
