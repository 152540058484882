import { GetNewRestaurantsParams } from '../../../Interface/IGetNewRestaurantsParams';
import ApiBaseUrl from '../../ApiBaseURL';
const token = localStorage.getItem('authToken');

// Generic function to handle API responses
const handleResponse = async (response: Response) => {
    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error ${response.status}: ${errorText || response.statusText}`);
    }
    return response.json();
};

// Helper function for API requests
const apiFetch = async (endpoint: string, options: RequestInit = {}) => {

    console.log('mytoken',token);

    const headers = {
        'Content-Type': 'application/json',
        ...(token && { 'Authorization': `Bearer ${token}` }),
        ...options.headers,
    };
    const response = await fetch(`${ApiBaseUrl.apiBaseUrl}${endpoint}`, { ...options, headers });
    return handleResponse(response);
};


// Define the parameters interface for getRestaurants
export interface GetRestaurantsParams {
    currentLat: number;
    currentLon: number;
    distanceKm: number;
    page?: number;
    pageSize?: number;
}

// Generic function to get restaurants
export const getRestaurants = async (params: GetRestaurantsParams) => {
    // Construct the query string
    const queryString = new URLSearchParams({
        currentLat: params.currentLat.toString(),
        currentLon: params.currentLon.toString(),
        distanceKm: params.distanceKm.toString(),
        page: (params.page || 1).toString(),
        pageSize: (params.pageSize || 10).toString(),
    }).toString();

    // Fetch data using the constructed URL
    console.log("Fetching restaurants with params:", queryString);
    const data = await apiFetch(`/Restaurants/Search?${queryString}`, {
        method: 'GET',
    });

    // Return the structured data
    return {
        totalRecords: data.totalRecords,
        pageSize: data.pageSize,
        pageNumber: data.pageNumber,
        totalPages: data.totalPages,
        restaurants: data.restaurants || [],
    };
};


// Interface for getRestaurantById parameters
export interface GetRestaurantByIdParams {
    restaurant_id: number;
    currentLat: number;
    currentLon: number;
}
// Function to get restaurant by ID
export const getRestaurantById = async (params: GetRestaurantByIdParams) => {
    // Construct the query string
    const queryString = new URLSearchParams({
        restaurant_id: params.restaurant_id.toString(),
        currentLat: params.currentLat.toString(),
        currentLon: params.currentLon.toString(),
    }).toString();

    // Fetch data using the constructed URL
    console.log("Fetching restaurant by ID with params:", queryString);
    const data = await apiFetch(`/Restaurants/GetRestaurantById?${queryString}`, {
        method: 'GET',
    });

    // Return the restaurant data
    return data;
};


// Function to get items with category by Restaurant ID
export const getItemsWithCategoryByRestaurantId = async (restaurantId: number) => {
    // Construct the query string
    const queryString = new URLSearchParams({
        RestaurantId: restaurantId.toString(),
    }).toString();

    // Fetch data using the constructed URL
    console.log("Fetching items with category by Restaurant ID:", restaurantId);
    const data = await apiFetch(`/Items/GetItemsWithCategoryByRestaurantIdV1?${queryString}`, {
        method: 'GET',
    });

    // Return the item data
    return data;
};


// Function to get item with choices and toppings by Item ID
export const getItemWithChoicesAndToppingsById = async (itemId: number) => {

    // Construct the query string
    const queryString = new URLSearchParams({
        itemId: itemId.toString(),
    }).toString();

    // Fetch data using the constructed URL
    console.log("Fetching item with choices and toppings by Item ID:", itemId);
    const data = await apiFetch(`/Items/GetItemWithChoicesAndToppingsById?${queryString}`, {
        method: 'GET',
    });

    console.log('ddddd',data);
    // Return the item data
    return data;
};


// Function to add an item to the cart with variant and toppings
export const addToCart = async (itemId: number, quantity: number, restaurantId: number, variantId: number | null, toppings: number[]) => {
    const payload = {
        itemId,
        quantity,
        restaurantId,
        variantId,
        toppings
    };

    console.log("Adding item to cart with payload:", payload);

    return apiFetch('/FruitCityUserSession/AddToCartV1', {
        method: 'POST',
        body: JSON.stringify(payload)
    });
};

// Function to get cart items by Restaurant ID
export const getCartItems = async (restaurantId: number) => {

    debugger;   
    // Construct the query string
    const queryString = new URLSearchParams({
        restaurant_id: restaurantId.toString(),
    }).toString();

    // Fetch data using the constructed URL
    console.log("Fetching cart items for Restaurant ID:", restaurantId);
    const data = await apiFetch(`/FruitCityUserSession/GetCartItemsV1?${queryString}`, {
        method: 'GET',
    });

    console.log('cart',data);
    debugger;   
    // Return the cart items data
    return data;
};

// Function to send an order
export const sendOrder_Checkout = async (orderData: {
    user_id: string;
    restaurant_id: number;
    shipping_lat: number;
    shipping_lot: number;
    shipping_address: string;
    specialInstruction: string;
    OrderType:number;
}) => {
    console.log("Sending order with data:", orderData);

    // Make the API request
    return apiFetch('/checkout/sendOrderV1', {
        method: 'POST',
        body: JSON.stringify(orderData)
    });
};



// Function to get newly registered restaurants
export const getNewRestaurants = async (params: GetNewRestaurantsParams) => {
    // Construct the query string
    const queryString = new URLSearchParams({
        page: (params.page || 1).toString(),
        pageSize: (params.pageSize || 10).toString(),
    }).toString();

    // Fetch data using the constructed URL
    console.log("Fetching new restaurants with params:", queryString);
    const data = await apiFetch(`/Restaurants/GetNewRestaurants?${queryString}`, {
        method: 'GET',
    });

    // Return the structured data
    return {
        totalRecords: data.totalRecords,
        pageSize: data.pageSize,
        pageNumber: data.pageNumber,
        totalPages: data.totalPages,
        restaurants: data.restaurants || [],
    };
};
