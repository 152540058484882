import React, { createContext, useContext, useEffect, useState } from 'react';
import { CartItem } from '../Interface/CartItem';
import { getCartItems } from '../Common/api/restaurants/Restaurants';


interface CartContextType {
  cartItems: CartItem[];
  refreshCart: () => void;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [cartItems, setCartItems] = useState<CartItem[]>([]);

  const refreshCart = async () => {
    try {
      const data = await getCartItems(1); // Provide restaurant_id as needed
      setCartItems(data.cartItems);
    } catch (error) {
      console.error('Error fetching cart items:', error);
    }
  };

  useEffect(() => {
    refreshCart(); // Initial fetch
  }, []);

  return (
    <CartContext.Provider value={{ cartItems, refreshCart }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};
