import React, { useState } from 'react';
import InputField from '../../components/InputFileds/InputField';
import Button from '../../components/Buttons/Button';
import { register } from '../../Common/api/auth/Auth';
import AlertMessage from '../../components/Alert_Message/AlertMessage';
import Loader from '../../components/Loader/Loader'; // Import the Loader component
import './RegisterPage.css';
import { AiOutlineWarning } from 'react-icons/ai';


interface RegisterPageProps {
    onClose: () => void;
    onLoginClick: () => void;
}

interface FormErrors {
    firstName?: string;
    lastName?: string;
    email?: string;
    password?: string;
    confirmPassword?: string;
}

const RegisterPage: React.FC<RegisterPageProps> = ({ onClose, onLoginClick }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [alert, setAlert] = useState<{ severity: 'success' | 'info' | 'warning' | 'error'; message: string } | null>(null);
    const [loading, setLoading] = useState(false); // Loader state

    const validateForm = () => {
        const errors: FormErrors = {};

        if (!firstName) errors.firstName = "First Name is required.";
        if (!lastName) errors.lastName = "Last Name is required.";
        if (!email) {
            errors.email = "Email is required.";
        } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
            errors.email = "Invalid email format.";
        }
        if (!password) errors.password = "Password is required.";
        if (!confirmPassword) {
            errors.confirmPassword = "Please confirm your password.";
        } else if (password !== confirmPassword) {
            errors.confirmPassword = "Passwords do not match.";
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleRegister = async () => {
        if (!validateForm()) return;

        setLoading(true); // Show loader
        try {
            await register({ Email: email, Password: password, FirstName: firstName, LastName: lastName });
            setAlert({ severity: 'success', message: "Registration successful! You may now log in." });
            setFirstName('');
            setLastName('');
            setEmail('');
            setPassword('');
            setConfirmPassword('');
        } catch (error) {
            setAlert({ severity: 'error', message: "Registration failed. Please try again." });
        } finally {
            setLoading(false); // Hide loader
        }
    };

    const handleAlertClose = () => {
        setAlert(null);
    };

    return (
        <>
            <div className="overlay"></div>
            <div className="register-modal">
                <h2 className="modal-title">Register</h2>

                {/* Show alert message immediately */}
                {alert && (
                    <AlertMessage 
                        severity={alert.severity} 
                        message={alert.message} 
                        onClose={handleAlertClose} 
                    />
                )}

                {/* First Name Input Field */}
                <div className="input-container">
                    {formErrors.firstName && <p className="error-text">{formErrors.firstName}</p>}
                    <div className={`input-field ${formErrors.firstName ? 'input-error' : ''}`}>
                        <InputField 
                            type="text" 
                            value={firstName} 
                            onChange={(e) => setFirstName(e.target.value)} 
                            placeholder=" " 
                            label="First Name" 
                            required 
                         />
                         {formErrors.firstName && (
    <span className="question-mark-icon">?</span>
)}
                         
                    </div>
                </div>

                {/* Last Name Input Field */}
                <div className="input-container">
                    {formErrors.lastName && <p className="error-text">{formErrors.lastName}</p>}
                    <div className={`input-field ${formErrors.lastName ? 'input-error' : ''}`}>
                        <InputField 
                            type="text" 
                            value={lastName} 
                            onChange={(e) => setLastName(e.target.value)} 
                            placeholder=" " 
                            label="Last Name" 
                            required 
                        />
                        {formErrors.lastName && <span className="question-mark">?</span>}
                    </div>
                </div>

                {/* Email Input Field */}
                <div className="input-container">
                    {formErrors.email && <p className="error-text">{formErrors.email}</p>}
                    <div className={`input-field ${formErrors.email ? 'input-error' : ''}`}>
                        <InputField 
                            type="email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            placeholder=" " 
                            label="Email" 
                            required 
                        />
                        {formErrors.email && <span className="question-mark">?</span>}
                    </div>
                </div>

                {/* Password Input Field */}
                <div className="input-container">
                    {formErrors.password && <p className="error-text">{formErrors.password}</p>}
                    <div className={`input-field ${formErrors.password ? 'input-error' : ''}`}>
                        <InputField 
                            type="password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            placeholder=" " 
                            label="Password" 
                            required 
                        />
                        {formErrors.password && <span className="question-mark">?</span>}
                    </div>
                </div>

                {/* Confirm Password Input Field */}
                <div className="input-container">
                    {formErrors.confirmPassword && <p className="error-text">{formErrors.confirmPassword}</p>}
                    <div className={`input-field ${formErrors.confirmPassword ? 'input-error' : ''}`}>
                        <InputField 
                            type="password" 
                            value={confirmPassword} 
                            onChange={(e) => setConfirmPassword(e.target.value)} 
                            placeholder=" " 
                            label="Confirm Password" 
                            required 
                        />
                        {formErrors.confirmPassword && <span className="question-mark">?</span>}
                    </div>
                </div>

                <Button className="primary-btn" onClick={handleRegister} disabled={loading} loading={loading}>
                    Register
                </Button>

                <div className="login-link">
                    <p>
                        Already have an account? <button onClick={onLoginClick} className="link-button">Login here</button>
                    </p>
                </div>
            </div>
        </>
    );
};

export default RegisterPage;
