// src/components/Popup/Popup.tsx
import React from 'react';
import './Popup.css';

interface PopupProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const Popup: React.FC<PopupProps> = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div className="modal fade show" style={{ display: 'block', opacity: 1 }}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header" style={{ border: 'none', padding: '0' }}>
                        <button 
                            className="btn-close" 
                            onClick={onClose} 
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body" style={{ padding: '0' }}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Popup;
