// src/components/Loader.tsx
import React from 'react';
import { SyncLoader } from 'react-spinners';
import './Loader.css';

interface LoaderProps {
    loading: boolean;
    color?: string;
    size?: number;
}

const Loader: React.FC<LoaderProps> = ({ loading, color = "#c2185b", size = 10 }) => {
    return (
        <div className="loader-container">
            <SyncLoader color={color} loading={loading} size={size} />
        </div>
    );
};

export default Loader;
