import React, { useState } from 'react';
import './Orderonline&dinning.css';

interface CardProps {
    image: string;
    title: string;
    description: string;
}

const cardData: CardProps[] = [
    {
        image: 'https://via.placeholder.com/340x180.png?text=Order+Online', 
        title: 'Order Online',
        description: 'Stay home and order to your doorstep',
    },
    {
        image: 'https://via.placeholder.com/340x180.png?text=Dining', 
        title: 'Dining',
        description: "View the city's favourite dining venues",
    },
    {
        image: 'https://via.placeholder.com/340x180.png?text=TakeAway', 
        title: 'TakeAway',
        description: "View the city's favourite TakeAway venues",
    },
];

interface OrderProps {
    onOpenPopup: (coordinates?: [number, number]) => void;
}

const Order: React.FC<OrderProps> = ({ onOpenPopup }) => {
    const [selectedCard, setSelectedCard] = useState<CardProps | null>(null);

    const handleCardClick = (card: CardProps) => {
        // Optionally set card-specific data here, for example:
        setSelectedCard(card);
        // Open the popup with coordinates or any card-related data
        onOpenPopup([0, 0]); // You can pass actual coordinates or other details
    };

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                {cardData.map((card, index) => (
                    <div key={index} className="col-md-4 mb-4"> 
                        <div 
                            className="card shadow-sm hover-card" 
                            onClick={() => handleCardClick(card)} // Handle card click
                        >
                            <img src={card.image} alt={card.title} className="card-img-top" />
                            <div className="card-body">
                                <h5 className="card-title">{card.title}</h5>
                                <p className="card-text">{card.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Order;
