// src/components/ForgotPassword/ForgotPassword.tsx
import React, { useState } from 'react';
import InputField from '../InputFileds/InputField';
import Button from '../Buttons/Button';
import AlertMessage from '../Alert_Message/AlertMessage';
import { forgotPassword } from '../../Common/api/auth/Auth'; // Adjust the import path as necessary
import Loader from '../../components/Loader/Loader'; // Import the generic Loader

interface ForgotPasswordProps {
    onClose: () => void;
    onLoginClick: () => void;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ onClose, onLoginClick }) => {
    const [email, setEmail] = useState('');
    const [alert, setAlert] = useState<{ severity: 'success' | 'error'; message: string } | null>(null);
    const [loading, setLoading] = useState(false); // Loader state

    const handleForgotPassword = async () => {
        setLoading(true); // Show loader
        try {
            // Call the forgotPassword API method
            await forgotPassword(email);
            setAlert({ severity: 'success', message: "Reset link sent to your email!" });
        } catch (error) {
            setAlert({ severity: 'error', message: error instanceof Error ? error.message : "Failed to send reset link. Please try again." });
        } finally {
            setLoading(false); // Hide loader
        }
    };

    const handleAlertClose = () => {
        setAlert(null);
    };

    return (
        <div className="forgot-password-modal">
            <h2 className="modal-title">Forgot Password</h2>

            {alert && (
                <AlertMessage 
                    severity={alert.severity} 
                    message={alert.message} 
                    onClose={handleAlertClose} 
                />
            )}

            <InputField 
                type="email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                placeholder=" " 
                label="Email" 
                required 
            />

            <Button className="primary-btn" onClick={handleForgotPassword} loading={loading}>
                Send Reset Link
            </Button>
            <p>
                Remember Password?
                <button onClick={onLoginClick} className="link-button">Login here</button>
            </p>
        </div>
    );
};

export default ForgotPassword;
