import React from 'react';
import './AdvanceFilter.css'; // Ensure you have styles for the modal
import { useNavigate } from 'react-router-dom';
import Button from '../../Buttons/Button';

interface AdvanceFilterProps {
    onClose: () => void;
}

const AdvanceFilter: React.FC<AdvanceFilterProps> = ({ onClose }) => {
    const navigate = useNavigate();

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-contents" onClick={(e) => e.stopPropagation()}>
                <h1>Advance Filter</h1>
                <p>Advance Filter Data Here</p>
                {/* Add your filter inputs here */}
                <Button className="primary-btn" onClick={onClose} >
                Close
            </Button>
            </div>
        </div>
    );
};

export default AdvanceFilter;
