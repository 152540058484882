import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SharedLayout from './pages/Layout/SharedLayout';
import HomePage from './pages/Home/HomePage';
import 'bootstrap/dist/css/bootstrap.min.css';
import RestaurantPage from './pages/Restaurants/RestaurantPage';
import RestaurantDetailPage from './pages/Restaurants/RestaurantDetailPage';
import OrderList from './components/Checkout/OrderList';
import CheckoutPage from './pages/checkout/checkoutPage';
import TrackingPage from './pages/tracking/trackingPage';
function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SharedLayout />}>
          <Route index element={<HomePage />} />
          <Route path="restaurant" element={<RestaurantPage />} />
          <Route path="restaurant/:id/:name" element={<RestaurantDetailPage />} /> {/* Dynamic route for restaurant details */}
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/tracking" element={<TrackingPage />} />

          {/* Add other routes as needed */}
          {/* <Route path="about" element={<AboutPage />} /> */}
        </Route>
      </Routes>

  
    </BrowserRouter>
  );
}

export default App;
